import React, { Component } from 'react'

export class company extends Component {
  render() {
    return (
      <div id="company">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="company-text">
                <h2 className="company-title small-title-in-mobile">Qui sommes-nous?</h2>
                <h3 className="company-subtitle">L'entreprise</h3>
                <p>Créé en 2008, REALIZ’ACT est un cabinet de conseil spécialisé en Ressources Humaines, proposant des prestations sur mesure pour les PME</p>
                <p>REALIZ'ACT accompagne les PME au quotidien dans la gestion des ressources humaines</p>
                <p>REALIZ'ACT met ses 30 ans d'expertise professionelle à disposition des entreprises à taille humaine qui n'ont pas de service RH ou n'ont pas besoin de compétence RH à temps plein</p>
                <p>REALIZ'ACT travaille avec un réseau de partenaires qualifié pour répondre aux attentes de ses clients et lui garantir la meilleure expertise selon ses besoins</p>
              </div>
            </div>
          <div className="col-xs-12 col-md-6"> <img src="img/nouveau_logo_realizact.jpg" className="img-responsive company-logo" alt=""/> </div>
        </div>
      </div>
    </div>
    )
  }
}

export default company
