import React, { Component } from 'react'

export class profile extends Component {
  render() {
    return (
      <div id="profile">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-4"> <img src="img/profile.jpeg" className="img-responsive photo-profile overlay" alt=""/> </div>
            <div className="col-xs-12 col-md-8">
              <div className="profile-text">
                <h2 className='small-title-in-mobile'>Qui sommes-nous?</h2>
                <h3 className="profile-subtitle">Le portrait</h3>
                <p className='paragraph-profil'>Diplômée de <strong>Sciences Po Paris </strong> en 1989, j’ai exercé mon métier de DRH en entreprise pendant 20 ans, d’abord dans des univers industriels (chez Colgate Palmolive puis dans le textile chez DMC), puis dans le secteur musical (EMI), l’assurance/assistance (ELVIA, groupe MONDIAL ASSISTANCE), les services aux entreprises (International SOS)</p>
                <p className='paragraph-profil'>J’ai privilégié des sociétés à taille humaine dans lesquelles les missions et enjeux très variés étaient proches du terrain</p>
                <p className='paragraph-profil'>En 2008, je décidais de créer ma propre structure pour accompagner les petites entreprises dépourvues de fonction RH dans leurs problématiques humaines et sociales</p>
                <p className='paragraph-profil'>En 2014, je retrouvais les bancs de l’université de l'<strong>Université Paris I, Panthéon-Sorbonne</strong> et décrochais l'année suivante un Master 2, Juriste Droit Social, domaine qui me passionne et pour lequel j'aime accompagner des clients</p>
                <p className='paragraph-profil'>Disponibilité, dynamisme, capacité d’écoute, rigueur, attachement à des valeurs humaines : respect, bienveillance, honnêteté, me caractérisent</p>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 contact-block">
                <div className="contact-item">
                  <div>
                    <p><span><i className="fa fa-phone"></i></span>{" "}
                      +33 6 81 69 30 12
                    </p>
                  </div>
                  <p><span><i className="fa fa-envelope-o"></i></span>{" "}<a className='email-link' href="mailto:d.viviani@realizact.com">
                  d.viviani@realizact.com</a>
                  </p>
                  <div className="contact-item-linkedin">
                    <a href="https://www.linkedin.com/in/delphineviviani/" target="_blank">
                      <p><span><i className="fa fa-linkedin"></i> linkedin</span>{" "}
                      : voir le profil
                      </p>
                    </a>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default profile