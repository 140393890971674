import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Company from './components/company';
import Profile from './components/profile';
import References from './components/references';
import Missions from './components/missions';
import Contact from './components/contact';
// import $ from 'jquery';

function App() {
  return (
    <div>
      <Navigation />
      <Header/>
      <Company />
      <Profile />
      <Missions />
      <References />
      <Contact />
    </div>
  );
}

export default App;
