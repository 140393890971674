import React, { Component } from "react";

export class references extends Component {
  render() {
    return (
      <div id="references" className="section-bg">
        <div className="container">
          <div className="section-title text-center">
            <h2>Nos références</h2>
            <p>Quelques uns de nos clients</p>
          </div>
          <div className="row no-gutters clients-wrap clearfix wow fadeInUp lol" >
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
              <div className="client-logo aejqs-logo"> <img src="img/references/aejqs.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/rdai.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/international-sos.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/odyssey_messaging.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/europ_assistance.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/mbvsi.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 client-logo-no-padding">
                <div className="client-logo"> <img src="img/references/ormazabal.png" className="img-fluid img-responsive" alt=""/> </div>
            </div>
          </div>
          <h3>Mais aussi </h3>
          <div className="other-reference-div">
            <p className="other-reference-name">- BMW </p>
            <p className="other-reference-name">- Pôle Emploi </p>
            <p className="other-reference-name">- Ecole des Mines </p>
            <p className="other-reference-name">- Elvetino </p>
            <p className="other-reference-name">... </p>
          </div>
        </div>
      </div>
    );
  }
}

export default references;
