import React, { Component } from "react";

export class missions extends Component {
  render() {
    return (
      <div id="missions">
        <div className="container">
          <div className="row">
            <div className="missions-div">
              <div className="missions-text">
                <h2 className="missions-title">Nos missions</h2>
                <p>Nous réalisons des missions très variées</p>
                <p>Nous sommes très orientés pragmatisme et nous adaptons à chaque situation et besoin</p>
                <p>Notre flexibilité et expérience nous permet de proposer des prestations sur mesure</p>
                <p className='padding-top-25'>Quelques exemples de missions:</p>
              </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-file-o"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Audit organisation RH </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-file-o"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Audit social </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-user"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Conseil en droit social </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-pencil"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Dialogue social : accompagnement à la mise en place et/ou renouvèlement IRP, négociation d'accords </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-edit"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">RH à temps partagé </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-tasks"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">RH de transition </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-certificate"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Accompagnement individuel du RRH, du dirigeant sur des problématiques RH </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-certificate"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Accompagnement des processus disciplinaires </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-certificate"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Suivi des contentieux (relais avocats) </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="prestation">
                    <div className="prestation-icon">
                      {" "}
                      <i className="fa fa-tasks"></i>{" "}
                    </div>
                    <div className="prestation-content">
                      <div className="prestation-meta">Mise en place d'entretiens annuels et professionnels </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default missions;
