import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <img src="img/logo.png" className="img-responsive photo-profile" alt=""/>
                  <p className="paragraph">
                    Cabinet de conseil spécialisé en ressources humaines
                  </p>
                <div className='contact-header'>
                  <div className='contact-header-text'>
                    <h3>Delphine Viviani</h3>
                    <h3>06 81 69 30 12</h3>
                    <h3>d.viviani@realizact.com</h3>
                    <h3><a href="https://www.linkedin.com/in/delphineviviani/" target="_blank"><i className="fa fa-linkedin"></i></a></h3>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
