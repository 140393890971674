import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contact</h2>
                  <p className="subtitle"> Une question ? <br/> Contactez-moi pour convenir d'un rendez-vous.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="">
                <div className="contact-row">
                  <img src="img/phone_2.png" className="img-responsive phone-icon" alt=""/>
                  <p>+33 6 81 69 30 12</p>
                </div>
                <a className="contact-block" href="mailto:d.viviani@realizact.com">
                  <div className="contact-row">
                    <img src="img/email.png" className="img-responsive phone-icon" alt=""/>
                    <p>d.viviani@realizact.com</p>
                  </div>
                </a>
                <a className="contact-texto" href="https://www.linkedin.com/in/delphineviviani/" target="_blank">
                  <div className="contact-row">
                    <img src="img/linkedin_1.png" className="img-responsive phone-icon" alt=""/>
                    <p>Voir le profil</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
